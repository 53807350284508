<template>
  <div class="wrapper">
    <Header />

    <main class="tech">
      <svg
        class="tech__picture"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 150"
      >
        <path
          d="M192.022 54.102c0 5.167-1.588 10.217-4.563 14.513-2.975 4.296-7.203 7.644-12.15 9.621a27.991 27.991 0 01-15.643 1.486c-5.251-1.007-10.075-3.495-13.862-7.149-3.786-3.653-6.365-8.307-7.409-13.375a25.269 25.269 0 011.54-15.092c2.049-4.774 5.52-8.853 9.972-11.724 4.452-2.87 9.687-4.402 15.041-4.402 7.181 0 14.067 2.752 19.144 7.65 5.078 4.9 7.93 11.544 7.93 18.472zm27.074 0c0-13.818-5.689-27.07-15.816-36.842C193.153 7.49 179.418 2 165.096 2s-28.057 5.49-38.184 15.26c-10.127 9.771-15.816 23.024-15.816 36.842C111.047 82.884 164.948 148 164.948 148s54.148-65.116 54.148-93.898z"
          fill="#41AD49"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M.287 113.136L61.142 26.8h23.21v58.752h14.96v27.584h-14.96V130H52.416v-16.864H.286zm56.13-4V126h23.935v-16.864h14.96V89.552h-14.96V30.8H63.216L8 109.136h48.416zm161.151 4L278.423 26.8h23.21v58.752h14.96v27.584h-14.96V130h-31.936v-16.864h-52.129zm56.129-4V126h23.936v-16.864h14.96V89.552h-14.96V30.8h-17.136l-55.216 78.336h48.416zM59 92.544H38.464L59 64.12v28.424zm-4-16.057L46.29 88.544H55V76.487zm221.281 16.057h-20.536l20.536-28.424v28.424zm-4-16.057l-8.711 12.057h8.711V76.487z"
          fill="#231F20"
        />
      </svg>

      <h1 class="tech__heading">Страница не найдена</h1>

      <Button
        to="/"
        class="tech__button"
      >
        Вернуться на Главную
      </Button>
    </main>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'NotFoundPage',
  metaInfo: {
    title: 'Страница не найдена',
  },
  components: {
    Footer,
    Header,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tech__picture {
  width: 1em;
  height: 0.47em;
  font-size: 18rem;

  @media (min-width: 768px) {
    font-size: 24rem;
  }
}

.tech__heading {
  margin-top: 2rem;
  margin-bottom: 0;

  font-size: var(--fs-h3);
  font-weight: bold;
  text-align: center;

  @media (min-width: 768px) {
    font-size: var(--fs-h2);
  }
}

.tech__text {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: var(--fs-text-lg);
}

.tech__button {
  margin-top: 2rem;
}
</style>
