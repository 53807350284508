<template>
  <header class="header">
    <Container>
      <div class="header__logo">
        <Logo />
      </div>
    </Container>
  </header>
</template>

<script>
import Container from '@/components/Container.vue';
import Logo from '@/components/Logo.vue';

export default {
  name: 'Header',
  components: {
    Container,
    Logo,
  },
};
</script>

<style lang="scss" scoped>
.header__logo {
  font-size: 1.5rem;
}
</style>
